const formatInputDate = date => `${date}_DATE`
const formatInputDay = (days, runDate) => `${days}_${runDate}`
const formatInputWeek = (weekNum, weekDay) => `${weekNum}_${weekDay}`
const formatInputMonth = firstDay => (firstDay ? 'FIRST_OF_MONTH' : 'LAST_OF_MONTH')

export default (type, value) => {
  switch (type) {
    case 'DATE':
      return typeof value === 'object' ? formatInputDate(value.day) : formatInputDate(value)
    case 'DAYS':
      return formatInputDay(value.numberDays, value.runDate)
    case 'WEEK':
      return formatInputWeek(value.weekNum, value.weekDay)
    case 'MONTHLY':
      return typeof value === 'object' ? formatInputMonth(value.firstDay) : formatInputMonth(value)
    default:
      return null
  }
}
