<template>
  <div class="d-flex align-items-baseline">
    <label for="custom-switch">
      <h5
        v-if="leftText"
        class="mr-1 mb-0 cursor-pointer"
        @click="val => { $emit('value', val) }"
      >
        {{ leftText }}
      </h5>
    </label>
    <b-form-checkbox
      id="custom-switch"
      v-model="switchValue"
      class="colorful-switch my-1"
      :style="`scale:${scale}`"
      :button-variant="variant"
      switch
      pill
      @input="val => { $emit('input', val) }"
    >
      <span
        v-if="showIcons"
        class="switch-icon-left"
      >
        <feather-icon
          icon="CheckIcon"
          color="#ffffff"
        />
      </span>
      <span
        v-if="showIcons"
        class="switch-icon-right"
      >
        <feather-icon
          icon="XIcon"
          color="#ffffff"
        />
      </span>
    </b-form-checkbox>
    <label for="custom-switch">
      <h5
        v-if="rightText"
        class="mb-0 cursor-pointer"
        @click="val => { $emit('value', val) }"
      >
        {{ rightText }}
      </h5>
    </label>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    showIcons: {
      type: Boolean,
      default: () => true,
    },
    checkedValue: {
      type: Boolean,
      default: () => true,
    },
    uncheckedValue: {
      type: Boolean,
      default: () => false,
    },
    scale: {
      type: Number,
      default: () => 1,
    },
    variant: {
      type: String,
      default: () => 'success',
    },
    leftText: {
      type: String,
      default: () => '',
    },
    rightText: {
      type: String,
      default: () => '',
    },
    initialValue: {
      type: Boolean,
      default: () => null,
    },
  },
  data() {
    return {
      switchValue: true,
    }
  },
  watch: {
    initialValue: {
      handler(val) {
        this.switchValue = val
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/pages/ui-colors.scss";
</style>
