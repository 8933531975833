<template>
  <div class="d-flex align-items-baseline">
    <b-button
      :id="tourKeyword"
      class="mb-2 mr-1"
      variant="flat-secondary"
      @click="callback ? $emit('back') : routeName ? $router.replace({ name: routeName, params, queries }) : $router.go(-1)"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        size="15"
      />
      Back
    </b-button>
    <h3 class="px-1 border-left border-2">
      {{ title }}
    </h3>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    routeName: {
      type: String,
      default: () => null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    queries: {
      type: Object,
      default: () => {},
    },
    callback: {
      type: Boolean,
      default: () => false,
    },
    tourKeyword: {
      type: String,
      default: () => null,
    },
  },
}
</script>
