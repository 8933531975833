<template>
  <b-modal
    :id="modal"
    :title="title"
    :ok-title="okTitle"
    :ok-variant="okVariant"
    :ok-disabled="okDisabled"
    :cancel-disabled="cancelDisabled"
    no-close-on-backdrop
    @ok="$emit('confirm', params)"
    @cancel="$emit('cancel')"
    @hidden="$emit('hidden')"
  >
    <b-alert
      v-if="alert"
      class="p-1"
      :variant="alertVariant"
      show
    >
      <div class="d-flex">
        <feather-icon
          :icon="icon ? icon : alertVariant === 'danger' ? 'AlertTriangleIcon' : 'InfoIcon'"
          :size="iconSize"
          class="mr-1"
          :color="getVariant"
        />
        <p>
          {{ alert }}
        </p>
      </div>
    </b-alert>
    <p>{{ description }}</p>
    <slot />
  </b-modal>
</template>

<script>
import { BModal, BAlert } from 'bootstrap-vue'
import props from './confirmModalProps'

export default {
  components: {
    BModal,
    BAlert,
  },
  props,
  computed: {
    getVariant() {
      switch (this.iconColor) {
        case 'danger':
          return '#ea5455'
        case 'success':
          return '#28c76f'
        default:
          return this.iconColor
      }
    },
  },
}
</script>
