export default {
  title: {
    type: String,
    default: () => '',
  },
  modal: {
    type: String,
    default: () => '',
  },
  description: {
    type: String,
    default: () => {},
  },
  alert: {
    type: String,
    default: () => null,
  },
  alertVariant: {
    type: String,
    default: () => 'primary',
  },
  params: {
    type: Object,
    default: () => {},
  },
  okVariant: {
    type: String,
    default: () => 'primary',
  },
  okTitle: {
    type: String,
    default: () => 'Confirm',
  },
  icon: {
    type: String,
    default: () => '',
  },
  iconSize: {
    type: String,
    default: () => '34',
  },
  iconColor: {
    type: String,
    default: () => '',
  },
  okDisabled: {
    type: Boolean,
    default: () => false,
  },
  cancelDisabled: {
    type: Boolean,
    default: () => false,
  },
}
