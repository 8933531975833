<template>
  <div>
    <!-- For Destination Account -->
    <div
      v-if="type === 'destination'"
      class="d-flex align-items-baseline mb-2"
    >
      <b-form-checkbox
        id="isEntireFamily"
        v-model="isEntireFamily"
        class="colorful-switch"
        switch
        @input="resetDestination"
      />
      <label
        for="isEntireFamily"
        class="cursor-pointer f-16"
      >
        {{ isEntireFamily ? 'Entire Family' : 'Particular Account' }}
      </label>
    </div>

    <!-- Source / Destination -->
    <b-row class="d-flex mb-4">
      <!-- Select Source / Destination -->
      <b-col class="pr-2">
        <h2
          v-if="type === 'source'"
          class="mb-2"
        >
          {{ selectedSchedule ? 'Source' : 'Select Source' }}
        </h2>
        <h2
          v-else
          class="mb-2"
        >
          {{ selectedSchedule ? 'Destination' : 'Select Destination' }}
        </h2>

        <validation-provider
          #default="{ errors }"
          :name="`${type} account`"
          rules="required"
        >
          <treeselect
            v-model="getModel"
            :options="isEntireFamily ? destinationWalletFamily : walletFamily"
            :disable-branch-nodes="!isEntireFamily"
            :disabled="type === 'source' && !!selectedSchedule"
            placeholder="Select Account"
            :clearable="false"
            @select="node => getInputFromTree(node)"
          >
            <label
              slot="option-label"
              slot-scope="{ node, labelClassName }"
              :class="labelClassName"
            >
              <div v-if="!node.isBranch">
                <i
                  :class="getWalletIcon(node.raw.type)"
                  class="fa"
                />
                {{ node.label }}
              </div>
              <span v-else>{{ node.label }}</span>
            </label>
          </treeselect>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

      <!-- Account Details -->
      <b-col class="ml-2">
        <h2 class="mb-2">
          Account Details
        </h2>

        <div v-if="(currentShare && currentWallet) || currentData.wallet">
          <h4>{{ currentShare ? currentShare.label : currentData.share.label }}</h4>
          <div class="d-flex align-items-center ml-2">
            <b-avatar
              :size="48"
              class="mr-2"
            >
              <i
                :class="getWalletIcon(currentShare ? currentShare.type : currentData.share.type)"
                class="f-25"
              />
            </b-avatar>
            <div>
              <p
                v-if="type === 'source'"
                class="mb-0"
              >
                Balance: {{ formatAmount(currentShare ? currentShare.balance : currentData.share.balance) }}
              </p>
              <p class="mb-0">
                Family: {{ currentWallet ? currentWallet.label : currentData.wallet.label }}
              </p>
            </div>
          </div>
        </div>
        <p
          v-else
          class="muted"
        >
          Select an account first
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import {
  BRow, BCol, BAvatar, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  computed, reactive, ref, toRefs,
} from '@vue/composition-api'
import useWallet from '@/utils/wallet'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BFormCheckbox,
    ValidationProvider,
    Treeselect,
  },
  props: {
    selectedSchedule: {
      type: Object,
      default: () => {},
    },
    selectedWallet: {
      type: Object,
      default: () => null,
    },
    type: {
      type: String,
      default: () => 'source',
    },
    walletFamily: {
      type: Array,
      default: () => {},
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    currentData: {
      handler(val) {
        if (val) {
          this.isEntireFamily = val.isEntireFamily ?? false
          this.shareUid = this.selectedWallet.shareUid
          this.walletUid = this.selectedWallet.walletUid
        }
      },
      immediate: true,
    },
  },
  setup(props, context) {
    const { getWalletIcon } = useWallet()

    const state = reactive({
      isEntireFamily: false,
      currentWallet: null,
      currentShare: null,
    })
    const shareUid = ref(null)
    const walletUid = ref(null)

    // Computed Properties
    const getModel = computed({
      get() {
        if (props.type === 'source') return shareUid.value
        return state.isEntireFamily ? walletUid.value : shareUid.value
      },
      set(value) {
        if (state.isEntireFamily) {
          walletUid.value = value
        } else shareUid.value = value
      },
    })

    const destinationWalletFamily = computed(() => {
      if (props.walletFamily) {
        return props.walletFamily.map(family => ({
          ...family,
          children: family.children ? family.children.map(child => ({
            ...child,
            isDisabled: true,
            type: child.wallet?.__typename,
          })) : [],
        }))
      }
      return []
    })

    // Methods
    const getInputFromTree = node => {
      if (props.type === 'source') {
        state.currentWallet = props.walletFamily.find(family => family.children.includes(node))
        state.currentShare = node
      } else {
        state.currentWallet = state.isEntireFamily ? node : props.walletFamily.find(family => family.children.includes(node))
        state.currentShare = state.isEntireFamily ? [] : node
      }

      shareUid.value = props.type === 'source' ? state.currentShare.id : state.currentShare.id ?? null
      walletUid.value = props.type === 'source' ? state.currentWallet.id : state.currentWallet.id ?? null

      const data = {
        shareUid: shareUid.value,
        walletUid: walletUid.value,
        currentWallet: state.currentWallet,
        currentShare: state.currentShare,
      }
      if (props.type === 'destination') data.isEntireFamily = state.isEntireFamily

      context.emit('wallet-selected', props.type, data)
    }

    const resetDestination = () => {
      shareUid.value = null
      walletUid.value = null
      state.currentShare = null
      state.currentWallet = null
      context.emit('change-destination-type', state.isEntireFamily)
    }

    return {
      ...toRefs(state),
      shareUid,
      walletUid,
      getModel,
      destinationWalletFamily,
      getInputFromTree,
      resetDestination,

      getWalletIcon,
    }
  },
}
</script>
