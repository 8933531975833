<template>
  <b-form-group :label="label">
    <b-form-radio
      v-for="option in options"
      :key="option.label"
      v-model="radioValue"
      class="my-1"
      :name="name"
      :value="option.value"
      @input="$emit('input', radioValue)"
    >
      <h5 class="mb-0">
        {{ option.label }}
      </h5>
      <small v-if="option.details">{{ option.details }}</small>
    </b-form-radio>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormRadio } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormRadio,
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    selected: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      radioValue: this.options?.[0]?.value,
    }
  },
  watch: {
    selected: {
      handler(val) {
        if (val) this.radioValue = val
      },
      immediate: true,
    },
  },
}
</script>
