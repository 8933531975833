<template>
  <b-overlay :show="processing">
    <b-row v-if="!noData">
      <b-col
        class="text-left"
        sm="8"
      >
        <h3>{{ formattedDate }}</h3>
      </b-col>
      <b-col class="text-right">
        {{ totalDays }} days
      </b-col>
    </b-row>
    <div
      class="calendar pt-2"
    >
      <template v-if="!noData">
        <span
          v-for="week of weeks"
          :key="week"
          class="item text-center"
        >
          {{ week }}
        </span>
      </template>
      <div
        v-for="i of blockStart"
        :key="`o${i}`"
        class="item"
      />
      <div
        v-for="i of noOfDays"
        :key="i.day"
        class="item"
      >
        <b-card
          :id="i.day"
          class="card block"
          :class="selectedDay == i.dayNum ? 'bg-success':getPresentStatus(i.day).class"
          role="button"
          no-body
          @click="()=> {
            if (noData) {
              $emit('handleClick', Number(i.dayNum) )
              return
            }
            getPresentStatus(i.day).isPresent && SHOW_RECORD(i.day, userId)
          }"
        >
          <b-card-body
            class="p-0 text-white text-center"
            style="margin-top: 4px"
          >
            <span>{{ i.dayNum }}</span>
          </b-card-body>
        </b-card>
        <b-tooltip
          v-if="!noData"
          :target="i.day"
          placement="bottom"
        >
          <span :set="duration = getPresentStatus(i.day).duration">
            <template v-if="duration">
              <feather-icon icon="ClockIcon" /> {{ duration ||'' }} <br>
              {{ i.day }}
            </template>
            <template v-else>
              {{ i.day }}
            </template>
          </span>
        </b-tooltip>
      </div>
      <div
        v-for="i of blockEnd"
        :key="`n${i}`"
        class="item"
      />
    </div>
  </b-overlay>
</template>
<script>
import {
  BCard, VBTooltip, BCardBody, BOverlay, BTooltip,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'

const moment = require('moment')

export default {
  components: {
    BCard,
    BCardBody,
    BOverlay,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    year: {
      type: Number,
      default: 0,
    },
    month: {
      type: Number,
      default: 0,
    },
    userId: {
      type: [Object, String],
      default: '',
    },
    noData: {
      type: Boolean,
      default: false,
    },
    selectedDay: {
      type: Number,
      default: 0,
    },
    presentDays: {
      type: Object,
      default: () => {},
    },
    presentDaysDuration: {
      type: Object,
      default: () => {},
    },
    processing: {
      type: Boolean,
      default: false,
    },
    shouldCallApi: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blockStart: 0,
      noOfDays: [],
      blockEnd: 0,
      formattedDate: '',
      weeks: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      presentDaysData: null,
      presentDaysDurationData: null,
    }
  },
  computed: {
    totalDays() {
      const days = Object.keys(this.presentDaysComputed || {})
      return this.noOfDays.filter(value => days.includes(value.day)).length
    },
    presentDaysComputed() {
      return this.presentDaysData || this.presentDays
    },
    presentDaysDurationComputed() {
      return this.presentDaysDurationData || this.presentDaysDuration
    },
  },
  watch: {
    year: {
      handler() {
        this.createBlocks()
      },
      immediate: true,
    },
    userId: {
      handler(newValue) {
        if (newValue) {
          this.createBlocks()
        }
      },
    },
  },
  methods: {
    getPresentStatus(date) {
      const isPresent = this.presentDaysComputed?.[date] === true
      return {
        class: isPresent ? 'bg-success' : 'bg-danger',
        isPresent,
        duration: this.presentDaysDurationComputed?.[date] ? this.CONVERT_HM(this.presentDaysDurationComputed[date]) : null,
      }
    },
    getDailyActivityRecords() {
      const startTime = `${this.year}-${this.month}-01 00:00:00`
      const endTime = `${this.year}-${this.month}-31 00:00:00`
      useApollo.activities.getDailyActivities({
        startTime,
        endTime,
        projectId: this.$store.state.project.selectedProject,
        userId: this.userId,
        first: -1,
      }).then(response => {
        const presentDays = {}
        const presentDaysDuration = {}
        const activities = response.data.user?.dailyActivities.data ?? []
        activities.forEach(item => {
          presentDays[item.date] = true
          presentDaysDuration[item.date] = item.duration
        })
        this.presentDaysData = presentDays
        this.presentDaysDurationData = presentDaysDuration
      }).catch(err => {
        console.error(err)
      }).finally(() => { this.processing = false })
    },
    createBlocks() {
      const current = `${this.year}-${this.month}-01`
      const startOfMonth = moment(current).clone().startOf('month')
      const endOfMonth = moment(current).clone().endOf('month')
      this.blockStart = startOfMonth.day()
      this.blockEnd = 7 - endOfMonth.day()
      const totalDays = []
      for (
        const m = moment(startOfMonth);
        m.isBefore(endOfMonth);
        m.add(1, 'days')
      ) {
        totalDays.push({
          day: m.format('YYYY-MM-DD'),
          dayNum: m.format('DD'),
        })
      }
      this.noOfDays = totalDays
      this.formattedDate = startOfMonth.format('MMM-YYYY')
      if (this.shouldCallApi) {
        this.getDailyActivityRecords()
      }
    },
  },
}
</script>
<style>
.calendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  width: 14.285714286%;
  height: 2em;
  margin-bottom: 1%; /* (100-32*3)/2 */
  position: relative;
}
.block {
  height: 100%;
  margin-right: 6%;
}
</style>
