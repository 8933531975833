<template>
  <div>
    <b-row class="flex-wrap">
      <b-col :md="fetchCompanySetting ? '5' : '12'">
        <b-form-group label="Settlement Type">
          <b-input-group>
            <b-form-select
              v-model="settlementType"
              :options="settlementTypeList"
            />
            <b-input-group-append>
              <b-button
                class="icon-only"
                variant="primary"
                :disabled="!settlementType"
                @click="$bvModal.show('pay-day-modal')"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col :md="fetchCompanySetting ? '3' : '6'">
        <b-form-group label="Summary">
          <b-form-input
            :value="paymentSentence"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col :md="fetchCompanySetting ? '4' : '6'">
        <b-form-group label="Upcoming Pay Day">
          <b-form-input
            :value="upcomingPayDay"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
      id="pay-day-modal"
      :visible="showModal"
      :title="settlementType"
      @ok="submit"
    >
      <validation-observer ref="payDayForm">
        <div v-if="settlementType === 'WEEK'">
          <b-form-group label="Sequence">
            <b-form-select
              v-model="settlementTypeOption.WEEK.weekNum"
              :options="sequenceList"
              required
              @change="updateInputWeek"
            />
          </b-form-group>
          <b-form-group label="Day">
            <b-form-select
              v-model="settlementTypeOption.WEEK.weekDay"
              :options="dayOfWeek"
              text-field="title"
              required
              @change="updateInputWeek"
            />
          </b-form-group>
        </div>
        <div v-else-if="settlementType === 'DAYS'">
          <validation-provider
            #default="{ errors }"
            rules="min_value:15|max_value:90|numeric"
            name="days"
          >
            <b-form-group label="Day">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    class="icon-only"
                    variant="danger"
                    :disabled="settlementTypeOption.DAYS.numberDays <= 15"
                    @click="settlementTypeOption.DAYS.numberDays --;updateInputDay()"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model="settlementTypeOption.DAYS.numberDays"
                  type="number"
                  :min="15"
                  :max="90"
                  placeholder="Number of days"
                  @change="updateInputDay"
                />
                <b-input-group-append>
                  <b-button
                    class="icon-only"
                    variant="success"
                    @click="settlementTypeOption.DAYS.numberDays ++;updateInputDay()"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Run Date">
            <b-form-datepicker
              v-model="settlementTypeOption.DAYS.runDate"
              :min="minRunDate"
              @input="updateInputDay"
            />
          </b-form-group>
        </div>
        <div v-else-if="settlementType === 'DATE'">
          <monthly-block
            :month="Number(TODAY({format: 'MM'}))"
            :year="Number(TODAY({format: 'YYYY'}))"
            :no-data="true"
            :selected-day="Number(settlementTypeOption.DATE.day)"
            @handleClick="updateInputDate"
          />
        </div>
        <div v-else-if="settlementType === 'MONTHLY'">
          <b-form-group>
            <b-form-radio
              v-model="settlementTypeOption.MONTHLY.type"
              class="mb-2"
              value="LAST_OF_MONTH"
              @change="updateMonthlyBasicPayment"
            >
              End of Month
            </b-form-radio>
            <b-form-radio
              v-model="settlementTypeOption.MONTHLY.type"
              value="FIRST_OF_MONTH"
              @change="updateMonthlyBasicPayment"
            >
              First Day of Month
            </b-form-radio>

          </b-form-group>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormSelect, BModal, BFormGroup, BFormInput, BInputGroupPrepend, BButton, BInputGroupAppend, BInputGroup, BFormDatepicker, BFormRadio,
} from 'bootstrap-vue'
import MonthlyBlock from '@/views/Attendance/MonthBlocks.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import moment from 'moment'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { min_value, max_value, numeric } from 'vee-validate/dist/rules'
import { dayOfWeek } from './timeValues'
import InputFormatter from './inputFormatter'

const PAYMENT_TYPES = {
  DATE: 'CompanyPaymentOnDate',
  WEEK: 'CompanyPaymentOnWeek',
  DAYS: 'CompanyPaymentOnDays',
  MONTHLY: 'CompanyPaymentOnMonth',
}
extend('numeric', numeric)
export default {
  components: {
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormRadio,
    ValidationObserver,
    ValidationProvider,
    MonthlyBlock,
    FeatherIcon,
  },
  props: {
    settlementTypeOptionProp: {
      type: Object,
      default: () => null,
    },
    fetchCompanySetting: {
      type: Boolean,
      default: () => true,
    },
    schedule: {
      type: Object,
      default: () => {},
    },
    currentData: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'create',
    },
    noDayIncrement: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      min_value,
      max_value,
      showModal: false,
      settlementType: null,
      settlementTypeList: [
        {
          text: 'Select a Settlement Type', value: null, disabled: true,
        },
        {
          text: 'Monthly Payment', value: 'MONTHLY',
        },
        {
          text: 'WeekDay of Month', value: 'WEEK',
        },
        {
          text: 'Number of Days', value: 'DAYS',
        },
        {
          text: 'Date of Month', value: 'DATE',
        },

      ],
      sequenceList: [
        {
          text: 'First', value: 'FIRST',
        },
        {
          text: 'Second', value: 'SECOND',
        },
        {
          text: 'Third', value: 'THIRD',
        },
        {
          text: 'Fourth', value: 'FOURTH',
        },
        {
          text: 'Last', value: 'LAST',
        },
      ],
      settlementTypeOption: {
        WEEK: {
          weekNum: '',
          weekDay: '',
        },
        DAYS: {
          runDate: '',
          numberDays: 15,
        },
        DATE: {
          day: '',
        },
        MONTHLY: {
          type: 'FIRST_OF_MONTH',
        },
      },
      form: {
        settlementType: '',
        settlementValue: '',
      },
      dayOfWeek,
      canShowModal: false,
      company: this.$store.state.project.selectedCompany,
    }
  },
  computed: {
    paymentSentence() {
      switch (this.settlementType) {
        case 'WEEK': {
          return `Every ${this.settlementTypeOption.WEEK.weekNum || ''} ${this.settlementTypeOption.WEEK.weekDay || ''}`
        }
        case 'DATE': {
          return `Every ${this.ORDINAL_NUMBER(this.settlementTypeOption.DATE.day)} day of month`
        }
        case 'DAYS': {
          return `Every ${this.settlementTypeOption.DAYS.numberDays} days `
        }
        case 'MONTHLY': {
          const { type } = this.settlementTypeOption.MONTHLY
          if (type === 'FIRST_OF_MONTH') {
            return 'First Day of Month'
          } if (type === 'LAST_OF_MONTH') {
            return 'End of the Month'
          }
          return ''
        }
        default:
          return null
      }
    },
    minRunDate() {
      return moment().subtract(this.settlementTypeOption.DAYS.numberDays - 1, 'days').format('YYYY-MM-DD')
    },
    upcomingPayDay() {
      switch (this.settlementType) {
        case 'WEEK': {
          const nthDayOfMonth = (monthMoment, day, weekNumber) => {
            if (!day || !weekNumber) {
              return '-'
            }
            let requiredMoment
            if (weekNumber === 5) {
              requiredMoment = monthMoment.endOf('month')
              const weekDay = day === 7 ? 0 : day
              while (requiredMoment.day() !== weekDay) {
                requiredMoment.subtract(1, 'day')
              }
            } else {
              const m = monthMoment.clone()
                .startOf('month') // go to the beginning of the month
                .day(day)
              if (m.month() !== monthMoment.month()) m.add(7, 'd')
              requiredMoment = m.add(7 * (weekNumber - 1), 'd')
            }

            if (requiredMoment.isBefore(moment())) {
              return nthDayOfMonth(requiredMoment.add('1', 'month'), day, weekNumber)
            }
            if (!this.noDayIncrement) requiredMoment = requiredMoment.add('1', 'day')
            return `${requiredMoment.format(this.COMMON.DATE_FORMAT)} (${this.fromNow(requiredMoment)})`
          }
          const days = {
            MONDAY: 1,
            TUESDAY: 2,
            WEDNESDAY: 3,
            THURSDAY: 4,
            FRIDAY: 5,
            SATURDAY: 6,
            SUNDAY: 7,
          }
          const week = {
            FIRST: 1,
            SECOND: 2,
            THIRD: 3,
            FOURTH: 4,
            LAST: 5,
          }
          return nthDayOfMonth(moment(), days[this.settlementTypeOption.WEEK.weekDay], week[this.settlementTypeOption.WEEK.weekNum])
        }
        case 'DATE': {
          if (!this.settlementTypeOption.DATE.day) return null
          let requiredDate = moment().date(this.settlementTypeOption.DATE.day)
          if (requiredDate.isBefore(moment())) {
            requiredDate = moment().add(1, 'month').date(this.settlementTypeOption.DATE.day)
          }
          if (!this.noDayIncrement) requiredDate = requiredDate.add('1', 'day')
          if (moment(requiredDate).isSame(moment())) return `${requiredDate.format(this.COMMON.DATE_FORMAT)} (Today)`
          return `${requiredDate.format(this.COMMON.DATE_FORMAT)} (${this.fromNow(requiredDate)})`
        }
        case 'DAYS': {
          const requiredDate = moment(this.settlementTypeOption.DAYS.runDate).add(this.settlementTypeOption.DAYS.numberDays, 'days')
          return `${requiredDate.format(this.COMMON.DATE_FORMAT)} (${this.fromNow(requiredDate)})`
        }
        case 'MONTHLY': {
          const monthlyType = this.settlementTypeOption.MONTHLY.type
          if (monthlyType === 'FIRST_OF_MONTH') {
            let requiredDate = moment().add('1', 'month').startOf('month')
            if (!this.noDayIncrement) requiredDate = requiredDate.add(1, 'day')
            return `${requiredDate.format(this.COMMON.DATE_FORMAT)} (${this.fromNow(requiredDate)})`
          }
          let requiredDate = moment().endOf('month')
          if (!this.noDayIncrement) requiredDate = requiredDate.add(1, 'day')
          return `${requiredDate.format(this.COMMON.DATE_FORMAT)} (${this.fromNow(requiredDate)})`
        }
        default:
          return null
      }
    },
  },
  watch: {
    settlementType() {
      if (this.canShowModal) {
        this.$bvModal.show('pay-day-modal')
        this.setSettlementValue(PAYMENT_TYPES[this.settlementType])
      }
    },
    currentData: {
      handler(val) {
        if (val && val.type) {
          const data = this.formatScheduleDetails(val)
          this.setSettlementData(data, false)
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.fetchCompanySetting) {
      this.getCompanyPaymentSettings()
      this.setSettlementValue()
    }
    if (this.currentData && !this.currentData.type) this.canShowModal = true
  },
  methods: {
    formatScheduleDetails(schedule) {
      const conf = schedule.configuration
      let data = []
      switch (schedule.type) {
        case 'WEEK':
          if (typeof conf === 'object') {
            data = [conf.weekNum, conf.weekDay]
          } else {
            data = conf.split('_')
          }
          return {
            __typename: 'CompanyPaymentOnWeek',
            weekNum: data[0],
            weekDay: data[1],
          }
        case 'MONTHLY':
          return { __typename: 'CompanyPaymentOnMonth', firstDay: conf === 'FIRST_OF_MONTH' }
        case 'DAYS':
          if (typeof conf === 'object') data = [conf.numberDays, conf.runDate]
          else data = conf.split('_')
          return {
            __typename: 'CompanyPaymentOnDays',
            numberDays: data[0],
            runDate: data[1],
          }
        case 'DATE':
          if (typeof conf === 'object') return { day: conf.day }
          return { __typename: 'CompanyPaymentOnDate', day: conf.split('_')[0] }
        default:
          return null
      }
    },
    getCompanyPaymentSettings() {
      useApollo.company.getCompanyPaymentSettings({ companyUid: this.company }).then(response => {
        const data = response.data.company.paymentSetting?.information
        if (data) this.setSettlementData(data)
      })
    },
    setSettlementData(settlementData, modalPopup = false) {
      this.canShowModal = modalPopup
      // eslint-disable-next-line no-underscore-dangle
      switch (settlementData.__typename) {
        case 'CompanyPaymentOnDays':
        // eslint-disable-next-line no-case-declarations
          const { runDate, numberDays } = settlementData
          this.settlementType = 'DAYS'
          this.settlementTypeOption.DAYS = {
            runDate,
            numberDays,
          }
          break
        case 'CompanyPaymentOnMonth':
          this.settlementType = 'MONTHLY'
          this.settlementTypeOption.MONTHLY = {
            type: settlementData.firstDay ? 'FIRST_OF_MONTH' : 'LAST_OF_MONTH',
          }
          break
        case 'CompanyPaymentOnWeek':
          // eslint-disable-next-line no-case-declarations
          const { weekNum, weekDay } = settlementData
          this.settlementType = 'WEEK'
          this.settlementTypeOption.WEEK = {
            weekNum,
            weekDay,
          }
          break
        default:
          this.settlementType = 'DATE'
          this.settlementTypeOption.DATE = { day: settlementData.day }
      }
      this.$nextTick(() => {
        this.canShowModal = true
      })
    },
    submit(bvModal) {
      bvModal.preventDefault()
      this.$refs.payDayForm.validate().then(success => {
        if (success) {
          this.hideModal()
          this.$nextTick(() => {
            this.$emit('updateInput', {
              type: this.settlementType,
              value: this.form.settlementValue,
            })
            this.$bvModal.hide('pay-day-modal')
          })
        }
      })
    },
    hideModal() {
      this.showModal = false
    },
    updateInputDate(value) {
      this.settlementTypeOption.DATE.day = value
      this.form.settlementValue = InputFormatter('DATE', value)
    },
    updateInputDay() {
      if (this.settlementTypeOption.DAYS.numberDays > 90) {
        this.settlementTypeOption.DAYS.numberDays = 90
      } else if (this.settlementTypeOption.DAYS.numberDays < 15) {
        this.settlementTypeOption.DAYS.numberDays = 15
      }
      const inputValue = this.settlementTypeOption.DAYS.numberDays

      this.settlementTypeOption.DAYS.day = inputValue
      this.form.settlementType = 'DAYS'
      this.form.settlementValue = InputFormatter('DAYS', { numberDays: inputValue, runDate: this.FORMAT_DATE(this.settlementTypeOption.DAYS.runDate) })
    },
    updateInputWeek() {
      this.form.settlementValue = InputFormatter('WEEK', { weekNum: this.settlementTypeOption.WEEK.weekNum, weekDay: this.settlementTypeOption.WEEK.weekDay })
    },
    updateMonthlyBasicPayment() {
      this.form.settlementValue = this.settlementTypeOption.MONTHLY.type
    },
    setSettlementValue(type = '') {
      // eslint-disable-next-line no-underscore-dangle
      const typeName = type || this.settlementTypeOptionProp?.__typename
      if (typeName === 'CompanyPaymentOnDate') {
        this.settlementTypeOption = {
          ...this.settlementTypeOption,
          DATE: {
            day: this.settlementTypeOption.day ?? 1,
          },
        }
        this.form = {
          settlementType: 'DATE',
          settlementValue: `${this.settlementTypeOption.day}_DATE`,
        }
      } else if (typeName === 'CompanyPaymentOnWeek') {
        this.settlementTypeOption = {
          ...this.settlementTypeOption,
          WEEK: {
            weekNum: this.settlementTypeOption.weekNum ?? 'FIRST',
            weekDay: this.settlementTypeOption.weekDay ?? 'SUNDAY',
          },
        }
        this.$nextTick(() => {
          this.form = {
            settlementType: 'WEEK',
            settlementValue: `${this.settlementTypeOption.WEEK.weekNum}_${this.settlementTypeOption.WEEK.weekDay}`,
          }
        })
      } else if (typeName === 'CompanyPaymentOnDays') {
        this.settlementTypeOption = {
          ...this.settlementTypeOption,
          DAYS: {
            runDate: this.settlementTypeOption.runDate,
            numberDays: this.settlementTypeOption.numberDays || 15,
          },
        }
        this.$nextTick(() => {
          this.form = {
            settlementType: 'DAYS',
            settlementValue: `${this.settlementTypeOption.DAYS.numberDays}_${this.settlementTypeOption.runDate}`,
          }
        })
      } else if (typeName === 'CompanyPaymentOnMonth') {
        const settlementType = this.settlementTypeOption.MONTHLY.type ?? 'END_OF_MONTH'
        this.settlementTypeOption = {
          ...this.settlementTypeOption,
          MONTHLY: {
            type: settlementType,
          },
        }
        this.$nextTick(() => {
          this.form = {
            settlementType: 'MONTHLY',
            settlementValue: settlementType,
          }
        })
      }
      this.$nextTick(() => {
        this.settlementType = this.form.settlementType
        setTimeout(() => {
          this.canShowModal = true
        }, 1500)
      })
    },
  },
}
</script>
